<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" >
        <span v-if="$route.name === 'showInActiveCompany'">{{ $t('transportation.companyRequest') }}</span>
        <span v-else>{{ $t('transportation.company') }}</span>
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">{{ $t('transportation.personal_data') }}</h5>
              </template>
              <b-row>
                <b-col md="12" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.name')"
                      :label="$t('transportation.name')"
                      v-model="data.name"
                      id="name"
                      name="name"
                      validate="required"
                      :disabled="$route.name !== 'editCompany'"
                    ></input-form>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.phone')"
                      :label="$t('transportation.phone')"
                      v-model="data.phone"
                      id="phone"
                      name="phone"
                      validate="required"
                      :disabled="$route.name !== 'editCompany'"
                    ></input-form>
                  </b-col>

                  <b-col md="4" class="mb-3">
                      <input-form
                        placeholder="اسم المالك"
                        label="اسم المالك"
                        v-model="data.ownerName"
                        id="ownerName"
                        name="ownerName"
                        validate="required"
                        :disabled="$route.name !== 'editCompany'"
                      ></input-form>
                </b-col>
                <b-col md="4" class="mb-3">
                      <input-form
                        :placeholder="$t('transportation.ownerNationalID')"
                        :label="$t('transportation.ownerNationalID')"
                        v-model="data.ownerNationalID"
                        id="ownerNationalID"
                        name="ownerNationalID"
                        validate="required"
                        :disabled="$route.name !== 'editCompany'"
                      ></input-form>
                </b-col>
                <b-col md="12" class="mb-3">
                      <input-form
                        :placeholder="$t('transportation.address')"
                        :label="$t('transportation.address')"
                        v-model="data.address"
                        id="address"
                        name="address"
                        validate="required"
                        :disabled="$route.name !== 'editCompany'"
                      ></input-form>
                </b-col>
                  <!-- <b-col md="4" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.email')"
                      :label="$t('transportation.email')"
                      v-model="data.email"
                      id="email"
                      name="email"
                      :disabled="$route.name !== 'editCompany'"
                    ></input-form>
                  </b-col> -->
                  <!-- <b-col md="4">
                  <div v-if="$route.name !== 'editCompany'">
                    <input-form
                      :placeholder="$t('transportation.city')"
                      :label="$t('transportation.city')"
                      v-model="data.countryCode"
                      style="font-size: 14px; line-height: 1.8;"
                      disabled
                      name="city"
                    ></input-form>
                  </div>
                  <div v-else>
                    <label class="joining-label">{{$t('transportation.city')}}</label>
                    <main-select
                    class="mb-3 joining-label"
                    :class="{ 'is-disabled': isDisabled }"
                    :name="city"
                    :reduce="city => city.id"
                    :placeholder="city"
                    :options="allCity"
                    v-model="data.city.id"
                    :disabled="$route.name === 'showInActiveCompany' || $route.name === 'showMerchant' "
                    label="name"
                    dir="rtl"
                    />
                  </div>
                  </b-col> -->
              </b-row>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card class="mb-4" v-for="(item, index) in data.companyDocs" :key="index">
              <cropper-images
                :label="item.document.name"
                @cropper-save="(file) => saveMasterImage(file, index)"
                :progressLoading="loadingLogo"
                :showProgress="false"
                :multi="false"
                :imageUrl="item.url"
                v-if="$route.name == 'editCompany'"
              />
              <div v-else>
                <span>{{ item.document.name }}</span>
                <hr />
                <div class="d-flex gap-3" style="gap: 10px">
                  <img
                    :src="item.url"
                    class="rounded-circle object-fit-cover"
                    style="width: 80px; height: 40px"
                  />
                  <b-button
                    variant="outline-primary"
                    id="show-btn"
                    @click="showModal({ url: item.url, title: item.document.name })"
                    >{{$t('transportation.view_image')}}</b-button
                  >
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-5" v-if="$route.name === 'showInActiveCompany'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center"
            style="gap: 10px"
          >
            <div>
              <b-button
                variant="primary"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingAccept"
                @click="handleAcceptCompany"
                >قبول</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
            <div>
              <b-button
                variant="info"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingComplate"
                @click="showComplatedModal"
                >استكمال البيانات</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-danger"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingReject"
                @click="showSuspensedModal"
                >رفض</b-button
              >
              <b-button
                v-else
                variant="outline-danger"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="$route.name == 'editCompany'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >تعديل</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <!-- show photo modal -->
    <b-modal
      ref="modal-photo"
      id="modal-photo"
      centered
      hide-footer
    >
      <template #modal-title>
        {{ selectedPhoto.title }}
      </template>
      <div class="image-container d-block text-center">
        <img :src="selectedPhoto.url" class="img-fluid" />
      </div>
    </b-modal>

    <!-- show complate Data modal -->
    <b-modal
      id="complateModal"
      size="lg"
      title="استكمال البيانات"
      style="direction: rtl"
      centered
      hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="d-flex flex-wrap">
            <b-col>
              <textarea-form
                placeholder="السبب"
                v-model="reason"
                label="سبب مختصر"
                name="reason"
                validate="required"
              ></textarea-form>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button
          v-if="saveButton"
          class="btn btn-primary float-right"
          variant="primary"
          disabled
        >
          <spinner-loading text="Loading" />
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="w-50"
          @click="handleComplateData"
          >Save</b-button
        >
      </div>
    </b-modal>

    <!-- show Rejected Driver modal -->
    <b-modal
      id="rejectedModal"
      size="lg"
      title="سبب ايقاف الشركة"
      style="direction: rtl"
      centered
      hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="d-flex flex-wrap">
            <b-col>
              <textarea-form
                placeholder="السبب"
                v-model="reason"
                label="سبب مختصر"
                name="reason"
                validate="required"
              ></textarea-form>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button
          v-if="saveButton"
          class="btn btn-primary float-right"
          variant="primary"
          disabled
        >
          <spinner-loading text="Loading" />
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="w-50"
          @click="handleRejectCompany"
          >Save</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../services/transportation'
export default {
  data () {
    return {
      loadingLogo: 0,
      loadingAccept: false,
      loadingComplate: false,
      selectedPhoto: {},
      data: {
        name: '',
        phone: '',
        city: '',
        companyDocs: []
      },
      allCity: []
    }
  },
  components: { },
  methods: {
    // Fetch Main things
    getAllCities () {
      api.getCities().then(res => {
        this.allCity = res.data.data
      })
    },
    getCompany () {
      api.getCompany(this.$route.params.id).then((res) => {
        this.data.name = res.data.data.name
        this.data.phone = res.data.data.phone
        this.data.email = res.data.data.email
        this.data.ownerNationalID = res.data.data.ownerNationalID
        this.data.companyDocs = res.data.data.companyDocs
        this.data.status = res.data.data.status
        this.data.ownerName = res.data.data.ownerName
        this.data.countryCode = res.data.data.countryCode
        this.data.address = res.data.data.address
      })
    },
    // save Image & update
    saveMasterImage (file, index) {
      console.log(file, index)
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options)
        .then((res) => {
          this.data.companyDocs[index].url = res.data.url
          this.showSuccessUploadFile()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // Handle Edit
    onSubmit () {
      if (this.$route.name === 'editCompany') {
        this.loadingSubmit = true
        api
          .editCompany(this.$route.params.id, this.data)
          .then((res) => {
            this.$router.go(-1)
          })
          .finally(() => {
            this.loadingSubmit = false
          })
      }
      this.loadingSubmit = false
    },
    // Show Models
    showModal (item) {
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.title }
    },
    showSuspensedModal () {
      this.reason = ''
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('rejectedModal')
    },
    showComplatedModal () {
      this.reason = ''
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('complateModal')
    },
    // Handle Actions
    handleAcceptCompany () {
      this.loadingAccept = true
      api
        .companyStatus(this.$route.params.id, 'accepted')
        .then((res) => {
          core.showSnackbar('success', 'تم  قبول المتجر بنجاح')
          this.$router.push({ name: 'inactiveCompanies' })
        })
        .finally(() => {
          this.loadingAccept = false
        })
    },
    handleRejectCompany () {
      this.saveButton = true
      api
        .companyStatus(this.suspendedId, 'rejected', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  رفض المتجر بنجاح')
          this.saveButton = false
          this.$bvModal.hide('rejectedModal')
          this.$router.push({ name: 'inactiveCompanies' })
        })
    },
    handleComplateData () {
      this.loadingComplate = true
      api
        .companyStatus(this.$route.params.id, 'processing', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  استكمال الطلب بنجاح')
          this.$router.push({ name: 'inactiveCompanies' })
        })
        .finally(() => {
          this.loadingComplate = false
        })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.$route.params.id) {
      this.getCompany()
      this.$root.$on('showSuspensedModal', this.showSuspensedModal)
      this.$root.$on('showComplatedModal', this.showComplatedModal)
    }
  }
}
</script>
